<template>
  <SideOver :title="title" :is-show="isShow" @onClose="$emit('onClose')" :label="content" :isLoading="isLoading">
    <div class="flex-1 h-full p-6 space-y-4 overflow-y-auto">
      <div v-for="item in formGroup">
        <Select v-if="item.type==='select'" :label="item.label" :items="item.items" customClass="w-full"
                :selectedItem="item.selectedItem"/>
        <Input v-else :label="item.label" :placeholder="item.placeholder" :type="item.type"/>
      </div>
			<Text size="sm" color="black" content="Program admins will be able to access the venture team accounts that are participating and add/update their research information."/>
    </div>
  </SideOver>
</template>

<script>
import Text from '../../../atoms/Text/Text.vue'
import Icon from '../../../atoms/Icons/Icons.vue'
import Input from '../../../molecules/Inputs/Component/Component.vue'
import Select from '../../../molecules/Selects/Component/Component.vue'
import Button from '../../../atoms/Button/Button.vue'
import SideOver from '../../SideOvers/WithBackgroundOverlay/WithBackgroundOverlay.vue'

export default {
  components: {
    Text,
    Icon,
    Input,
    Select,
    Button,
    SideOver
  },

  props: {
    isShow: {
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      default: ''
    },
    content: {
      type: String,
      default: ''
    },
    formGroup: {
      type: Array,
      default: () => []
    },
    button: {
      type: Object,
      default: () => {
      }
    },
    isLoading: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      show: false
    }
  },

  mounted() {
    setTimeout(() => {
      this.show = this.isShow;
    }, 50);
  },
}
</script>
