<template>
    <Template
        v-bind="{
            ...c.ecosystem_programs,
            items: programs,
            emptyViewBinding: emptyViewBinding,
            listHeader: {
                ...c.ecosystem_programs.listHeader,
                count: programs?.length,
            },
            pageTotalRecords: get(programs, 'length', 0),
            recordPerPage: 10,
            recordActivePage: 1,
            toggleSlideover: () => (isOpenAddProgram = true),
            onClickItem: (item) =>
                $router.push({
                    name: 'Ecosystem Program Detail',
                    params: { id: item?.id, companyId: company?.id },
                }),
        }"
    />
    <!-- <AddProgram
        v-bind="{
            ...c.add_program,
            isShow: isOpenAddProgram,
            isLoading,
        }"
        @onClose="() => (isOpenAddProgram = false)"
    /> -->
    <WithNoSwiper
        v-bind="{
            isShow: isOpenAddProgram,
            isLoading: isLoading,
            isSaving: isSaving,
            list: form,
            modelKey: 'modelField',
            title: form[0].title,
            content: form[0].content,
        }"
        :saveForm="saveForm"
        @onClose="
            () => {
                isOpenAddProgram = false;
            }
        "
    />
</template>

<script>
import Template from "../components/templates/EcosystemPrograms/EcosystemPrograms";
import AddProgram from "@/components/organisms/Modals/AddProgram/AddProgram";
import WithNoSwiper from "@/components/organisms/Modals/WithNoSwiper/WithNoSwiper";
import { programForm } from "../../.storybook/sampleData";
import { c } from "../components/constants.js";
import { get } from "lodash";

export default {
    components: {
        Template,
        AddProgram,
        WithNoSwiper,
    },
    data() {
        return {
            c,
            company: null,
            programs: [],
            get,
            isOpenAddProgram: false,
            isLoading: false,
            emptyViewBinding: {
                title: "Get started by adding your first program!",
                description: "Once your add a program, it will show up here",
                btnText: "Add a Program",
                icon: "BookOpenIcon",
            },
            form: programForm,
            isSaving: false,
        };
    },
    async created() {
        if (this.state.currentCompany?.id === this.$route.params?.companyId) {
            this.company = this.state.currentCompany;
        } else {
            const companies = await this.actions.company.getCompanies({
                where: { id: this.$route.params?.companyId },
                getValues: true,
                query: 'companyDefault'
            });
            console.log(companies);
            this.company = companies[0];
            this.actions.setCurrentCompany(this.company);
            console.log(this.company, "company");
        }
        // if (this.company && this.company.users && this.company.users.length > 0)
        //     this.form[0].fields[3].options = JSON.stringify(
        //         this.company.users.map((user) => {
        //             return { id: user.id, name: user.fullName };
        //         })
        //     );
        // else this.form[0].fields[3].options = JSON.stringify([]);
        this.company?.programs?.map((p) => {
            let ventures = 0,
                team = 0,
                opportunities = 0;
            p?.cohorts?.map((c) => {
                ventures += c?.ventures?.length || 0;
                c?.ventures?.map((v) => {
                    team += v?.users?.length || 0;
                    opportunities +=
                        v?.opportunities?.filter(
                            (o) =>
                                !o?.isArchived &&
                                o?.surveyOpportunities?.find(
                                    (s) => s?.responses?.length > 0
                                )
                        )?.length || 0;
                });
            });
            this.programs?.push({
                title: p?.name,
                actionLabel: p?.type,
                id: p?.id,
                subtitles: [
                    {
                        icon: "CalendarIcon",
                        name: "Cohorts",
                        count: p?.cohorts?.length || 0,
                    },
                    {
                        icon: "LightBulbIcon",
                        name: "Ventures",
                        count: ventures,
                    },
                    {
                        icon: "UsersIcon",
                        name: "People",
                        count: team,
                    },
                    {
                        icon: "ExclamationIcon",
                        name: "Opportunities",
                        count: opportunities,
                    },
                ],
            });
        });
    },
    methods: {
        async saveForm(data) {
            if (this.isSaving) return false;
            this.isSaving = true;
            const params = {
                name: data?.name,
                company: { connect: { id: this.company?.id } },
            };
            if (data.type) params["type"] = data?.type?.name;
            if (data.admin && data.admin.id)
                params["admins"] = { connect: { id: data.admin.id } };
            if (data.duration) params["duration"] = parseFloat(data?.duration);
            const response = await this.actions.saveFormData({
                mutation: "saveProgram",
                params,
            });
            if (response && response.id) {
                this.programs?.push({
                    title: response?.name,
                    actionLabel: response?.type,
                    id: response?.id,
                    subtitles: [
                        {
                            icon: "CalendarIcon",
                            name: "Cohorts",
                            count: response?.cohorts?.length || 0,
                        },
                        {
                            icon: "LightBulbIcon",
                            name: "Ventures",
                            count: 0,
                        },
                        {
                            icon: "UsersIcon",
                            name: "Team",
                            count: 0,
                        },
                        {
                            icon: "ExclamationIcon",
                            name: "Opportunities",
                            count: 0,
                        },
                    ],
                });
            }
            this.isSaving = false;
            this.isOpenAddProgram = false;
        },
    },
};
</script>

<style scoped></style>
